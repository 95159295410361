/* Mobile style for ProjectPreviewContainer */
@media only screen and (max-width: 768px) {
    .project-preview-container {
        width: 95vw;
        margin-right: 2.5vw;
    }

    .project-cover-image-container {
        height: calc(100vw * 2 / 3);
    }

    .project-cover-image {
        width: 100vw;
    }

    .project-cover-video {
        width: 100vw;
        display: inline-block;
        z-index: 20;
    }

    .project-cover-video-hidden {
        visibility: hidden;
    }

    .project-cover-image {
        width: 100vw;
    }

    .project-cover-image-hidden {
        visibility: hidden;
    }

    .project-info-container {
        margin-top: 5vh;
        word-spacing: -1px;
    }

    .project-title {
        margin-bottom: 2vh;
        font-size: 14px;
        letter-spacing: -0.5px;
    }

    .project-metadata-title {
        width: 100%
    }

    .project-metadata-value {
        width: 100%;
        margin-bottom: 1vh;
    }

    .project-info-row1 {
        width: 40%;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
    }

    .project-info-row2 {
        width: 60%;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        margin-bottom: 15vh;
    }
}

/* Desktop style for ProjectPreviewContainer */
@media only screen and (min-width: 769px) {
    .right-most-preview-container {
        margin-left: 1.5vw;
    }

    .project-preview-container {
        margin-right: 1.5vw;
        width: calc(80vh * 1.5) !important;
    }

    .project-cover-video {
        height: 80vh;
        display: inline-block;
        z-index: 20;
    }

    .project-cover-video-hidden {
        visibility: hidden;
    }

    .project-cover-image {
        height: 80vh;
    }

    .project-cover-image-hidden {
        visibility: hidden;
    }

    .project-info-container {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }

    .project-title {
        margin-bottom: 1.5vh;
    }

    .hovering-project-title {
        text-decoration: underline;
    }

    .project-metadata-container {
        display: flex;
    }

    .project-metadata {
        margin-right: 6%;
    }

    .project-metadata-title {
        margin-bottom: .2vh;
    }

    .project-info-row1 {
        width: 50%;
    }

    .project-info-row2 {
        width: 50%;
        float: right;
    }
}

.project-preview-container {
    color: black;
    text-decoration: none;
}

.project-cover-image-container {
    position: relative;
}

.project-cover-image {
    display: inline-block;
    position: absolute;
}

.project-info-container {
    display: flex;
}

.project-title {
    display: inline-block;
}

.project-metadata-title {
    margin-bottom: .2vh;
    font-style: italic;
}